<template>
    <section class="box">
        <div class="cart-data">
            <h2><b-icon icon="box-seam" class="icon"></b-icon> ข้อมูลสินค้า</h2>

            <div class="info">
                <div class="purchase">
                    <router-link class="picture"
                        :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', cartData.products[0].param)"
                        :title="cartData.products[0].size + ' ' + cartData.products[0].brand + ' ' + cartData.products[0].name">
                        <img :src="cartData.products[0].brandLogo" :title="cartData.products[0].brand" :alt="cartData.products[0].brand" class="brand" @error="helper.setNoImg($event, 'brand')" />
                        <img :src="cartData.products[0].picture" :title="cartData.products[0].name" :alt="cartData.products[0].name" @error="helper.setNoImg($event, 'product')" />
                    </router-link>

                    <div class="product-list">
                        <div class="order" v-for="(item, index) in cartData.products" :key="index">
                            <div class="title">
                                <b-icon icon="box-seam" class="icon"></b-icon>
                                <router-link
                                :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', item.param)"
                                :title="item.size + ' ' + item.brand + ' ' + item.name">
                                    ขนาดยาง {{ item.size }} {{ item.name }} (ปี {{ item.year }})
                                </router-link>
                                <button class="btn-remove-product" @click="removeProduct(item.sellerProductId)" v-if="cartData.products.length > 1">ลบสินค้า</button>
                            </div>

                            <div class="pay-item">
                                <div class="item no-line">
                                    <label>ราคาเส้นละ</label>
                                    <span class="price">{{ item.price }}</span>
                                </div>
                                <div class="item no-line">
                                    <label>จำนวน</label>
                                    <div class="qty">
                                        <button @click="changeQty(-1, item.sellerProductId)">-</button>
                                        <div class="number">{{ item.quantity }}</div>
                                        <button @click="changeQty(1, item.sellerProductId)">+</button>
                                    </div>
                                </div>
                                <div class="item no-line">
                                    <label>ราคารวม</label>
                                    <span class="price">{{ item.totalPrice }}</span>
                                </div>
                            </div>

                            <div class="additional-size" v-if="cartData.isAllowMultipleSize && cartData.products.length == 1">
                                <button @click="openModalAdditionalSize()"><b-icon icon="cart" class="icon"></b-icon> เพิ่มยางขนาดอื่น ลงในตะกร้า</button>
                                <label>* สำหรับลูกค้าที่ต้องการยางหน้าหลังคนละขนาด *</label>
                            </div>
                        </div>

                        <template v-if="cartData.deliveryData">
                            <h3 class="mt-3"><b-icon icon="truck" class="icon"></b-icon> ค่าจัดส่ง</h3>
                            <div class="pay-item">
                                <div class="item">
                                    <label>ค่าบริการจัดส่ง</label>
                                    <span v-if="!cartData.deliveryData.price">ฟรี</span>
                                    <span v-if="cartData.deliveryData.price" class="price">{{ cartData.deliveryData.price }}</span>
                                </div>
                            </div>
                        </template>

                        <template v-if="cartData.services.length > 0">
                            <h3 class="mt-3"><b-icon icon="gear" class="icon"></b-icon> บริการเพิ่มเติม</h3>
                            <div class="pay-item">
                                <div class="item" v-for="(item, index) in cartData.services" :key="index">
                                    <label :class="{ 'cut': !item.include }">{{ item.title }}</label>
                                    <span v-if="!item.price">ฟรี</span>
                                    <span v-if="item.price" class="price" :class="{ 'cut': !item.include }">{{ item.price }}</span>
                                    <template v-if="item.isTogglable && item.price">
                                        <button v-if="item.include" class="remove" @click="toggleService(item, false)">X</button>
                                        <button v-if="!item.include" class="add" @click="toggleService(item, true)">นำเข้ารายการ</button>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <template v-if="cartData.discounts.length > 0">
                            <h3 class="mt-3"><b-icon icon="tag" class="icon"></b-icon> ส่วนลด</h3>
                            <div class="pay-item">
                                <div class="item" v-for="(item, index) in cartData.discounts" :key="index">
                                    <label :class="{ 'cut': !item.include }">
                                        {{ item.title }}
                                        <div class="remark" v-if="item.remark">{{ item.remark }}</div>
                                    </label>
                                    <span v-if="item.price" class="price" :class="{ 'cut': !item.include }">- {{ item.price }}</span>
                                    <template v-if="item.isTogglable">
                                        <button v-if="item.include" class="remove" @click="toggleDiscount(item, false)">X</button>
                                        <button v-if="!item.include" class="add" @click="toggleDiscount(item, true)">ขอส่วนลด</button>
                                    </template>

                                </div>
                            </div>
                        </template>

                        <template v-if="cartData.benefits.length > 0">
                            <h3 class="mt-3"><b-icon icon="card-checklist" class="icon"></b-icon> สิทธิประโยชน์/การรับประกัน</h3>
                            <div class="pay-item other benefits">
                                <div class="item" :class="{ 'warranty': item.type === constants.BENEFIT_TYPE.WARRANTY }" v-for="(item, index) in cartData.benefits" :key="index">
                                    <label>
                                        {{ item.title }}
                                        <div class="remark" v-if="item.remark && item.type === constants.BENEFIT_TYPE.WARRANTY">{{ item.remark }}</div>
                                    </label>
                                    <span class="narration" v-if="item.remark && item.type !== constants.BENEFIT_TYPE.WARRANTY">{{ item.remark }}</span>
                                </div>
                            </div>
                        </template>

                        <div class="total mt-2">
                            <label>ยอดรวม</label>
                            <span>{{ cartData.orderTotal }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <modal-additional-size
                @close="closeModalAdditionalSize($event)"
                @chooseSize="chooseAdditionalSize($event)"
                :product="cartData.products[0]"
                :store="cartData.storeData"
                :isDisplay="isOpenModalAdditionalSize">
            </modal-additional-size>
        </div>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import ModalAdditionalSize from '@/components/order/ModalAdditionalSize';
import OrderService from '@/services/orderService';

export default {
    components: { ModalAdditionalSize },
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper,
            constants: Constants,
            isOpenModalAdditionalSize: false
        }
    },
	props: {
		cartData: Object
	},
	methods: {
        toggleService(item, include) {
            this.$emit('toggleService', {
                item: item,
                include: include
            });
        },
        toggleDiscount(item, include) {
            this.$emit('toggleDiscount', {
                item: item,
                include: include
            });
        },
        changeQty(operand, sellerProductId) {
            this.$emit('adjustQty', {
                operand: operand,
                sellerProductId: sellerProductId
            });
        },
        removeProduct(sellerProductId) {
            this.$swal(
                Helper.confirmAlert('ยืนยันการลบสินค้า', 'โปรดยืนยันหากต้องการลบสินค้าที่ท่านเลือกออกจากตะกร้า')
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    this.$emit('removeProduct', {
                        sellerProductId: sellerProductId
                    });
                }
            });
        },
        openModalAdditionalSize() {
            this.isOpenModalAdditionalSize = true;
        },
        closeModalAdditionalSize() {
            this.isOpenModalAdditionalSize = false;
        },
        async chooseAdditionalSize(data) {
            let loader = this.$modalLoader.render();
            const param = {
                id: data.id
            };
            
			const result = await OrderService.addProductSize(param);

			if (result.data.success) {
                this.$emit('loadCartData');
            } else {
                this.$swal(Helper.warningAlert('ไม่สามารถทำรายการได้', result.data.message));
            }

            this.isOpenModalAdditionalSize = false;
			loader.hide();
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/order/style/Cart.scss';

.purchase {
    display: flex;
    flex: 1;
    align-items: flex-start;

    @media only screen and (max-width: $screenExtraSmall) {
        display: block;
    }
    
    .picture {
        display: block;
        width: 193px;
        margin-right: 30px;
        
        @media only screen and (max-width: $screenMedium) {
            width: 140px;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            margin: 0 auto 15px;
            width: 100%;
            max-width: 150px;
        }

        img {
            display: block;
            width: 100%;
            border: 1px solid #ccc;

            &.brand {
                width: 70%;
                margin: 0 auto 7px;
            }
        }
    }

    .product-list {
        flex: 1;
    }

    .order {
        & + .order {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px dashed #aaa;
        }

        .title {
            display: flex;
            align-items: center;
            margin: 0;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #333;
            padding-bottom: 7px;
            margin-bottom: 10px;
            border-bottom: 1px dashed #ddd;

            a {
                color: #333;
            }

            .icon {
                opacity: 0.5;
                margin-right: 5px;
            }

            .btn-remove-product {
                margin-left: 7px;
                font-size: 11px;
                color: #fff;
                background: #cc0000;
                border: none;
                padding: 0 5px;
                outline: none;
            }
        }
    }
}

.additional-size {
    padding: 5px 0;

    button {
        border: none;
        background: #ff9900;
        padding: 7px 15px;
        color: #fff;
        font-size: 13px;
        @include borderRadius(20px);

        .bi {
            margin-right: 5px;
        }
    }

    label {
        margin-top: 5px;
        font-size: 12px;
        color: #333;
        display: block;
    }
}

h3 {
    font-size: 15px;
    font-weight: bold;
    color: #555;

    .icon {
        opacity: 0.5;
        margin-right: 5px;
    }
}

.pay-item {
    &.benefits {
        .item {
            padding-top: 8px;
            padding-bottom: 8px;

            &.warranty {
                label {
                    width: 100%;
                }
            }

            &:last-child {
                padding-bottom: 8px;
            }
        }
    }

    .item {
        display: flex;
        align-items: center;
        margin: 5px 0;
        padding-top: 5px;
        border-top: 1px dashed #ddd;

        &:last-child {
            padding-bottom: 5px;
            border-bottom: 1px dashed #ddd;
        }

        &.no-line {
            padding-top: 0;
            border-top: none;

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        .remark {
            font-size: 12px;
            line-height: 14px;
            margin-top: 5px;
            color: #999;
            padding-right: 24px;
        }

        label {
            font-size: 13px;
            line-height: 15px;
            color: #333;
            margin: 0;
            width: 40%;

            @media only screen and (max-width: $screenExtraSmall) {
                width: 53%;
            }

            &.cut {
                text-decoration: line-through;
                opacity: 0.3;
            }

            span {
                display: block;
                font-size: 12px;

                @media only screen and (max-width: $screenMedium) {
                    font-size: 11px;
                }
            }
        }

        span {
            font-size: 14px;

            &.price {
                color: #cc0000;

                &:before {
                    content: "฿";
                    margin-right: 5px;
                    color: #333;
                }

                &.cut {
                    opacity: 0.3;
                    color: #666;
                    text-decoration: line-through;
                }
            }

            &.narration {
                color: #cc0000;
            }
        }

        .remove {
            border: 1px solid #999;
            color: #999;
            background: none;
            width: 26px;
            height: 26px;
            margin-left: 20px;
            font-size: 14px;
            line-height: 14px;
            @include borderRadius(1000px);

            @media only screen and (max-width: $screenMedium) {
                width: 20px;
                height: 20px;
                font-size: 10px;
                line-height: 10px;
            }
        }
        
        .add {
            font-size: 12px;
            border: 1px solid #999;
            background: none;
            color: #555;
            margin-left: 20px;

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 11px;
                margin-left: 10px;
            }
        }

        .qty {
            display: flex;

            button {
                border: 1px solid #aaa;
                background: none;
                padding: 0 10px;

                &:first-child {
                    border-right: none;
                }

                &:last-child {
                    border-left: none;
                }
            }

            .number {
                border: 1px solid #aaa;
                font-size: 14px;
                padding: 0 10px;
            }
        }
    }
}

.total {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;

    @media only screen and (max-width: $screenMedium) {
        font-size: 18px;
        line-height: 20px;
    }

    label {
        margin: 0;
        width: 40%;
    }

    span {
        font-size: 24px;
        color: #cc0000;

        &:before {
            content: "฿";
            margin-right: 5px;
            color: #333;
            font-weight: normal;
        }
    }
}
</style>